// Basic
.table {
  width: 100%;
  border-collapse: collapse;
  overflow: auto;
  @apply text-grey-600;

  th,
  td {
    text-align: inherit;
    padding: 0.75rem;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    @apply border-grey-300;
  }

  th {
    font-weight: 600;
    font-size: 14px;
  }

  thead {
    text-align: left;

    > tr {
      height: 56px;
    }

    th {
      border-top: 0;
      @apply text-grey-600;
    }

    // Light heading
    &.thead--light {
      @apply bg-hover;
    }
  }

  tbody {
    > tr {
      height: 48px;
    }
  }

  // Bordered
  &.table--bordered {
    th,
    td {
      border: 1px solid;
      @apply border-grey-300;
    }
  }

  // Borderless
  &.table--borderless {
    th,
    td {
      border: 0;
    }
  }

  // Striped Rows
  &.table--striped {
    tbody tr:nth-child(odd) {
      @apply bg-hover;
    }

    tbody tr > td,
    tbody tr > th,
    tfoot tr > td,
    tfoot tr > th {
      border: 0;
    }
  }

  // Hoverable Rows
  &.table--hoverable {
    tbody tr:hover {
      @apply bg-hover;
    }
  }
}
