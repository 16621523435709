.timepicker-backdrop-overlay {
  z-index: 9999 !important;
}

.timepicker-overlay {
  z-index: 9999 !important;
}

.timepicker__header {
  @apply bg-primary #{!important};
}

.timepicker-button {
  @apply text-primary #{!important};
}
